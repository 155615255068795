import { Box, Typography, useTheme } from "@mui/material";
import React from "react";

const About = ({ id }) => {
  // let btn = document.querySelector(".mouse-cursor-gradient-tracking");
  // btn.addEventListener("mousemove", (e) => {
  //   let rect = e.target.getBoundingClientRect();
  //   let x = e.clientX - rect.left;
  //   let y = e.clientY - rect.top;
  //   btn.style.setProperty("--x", x + "px");
  //   btn.style.setProperty("--y", y + "px");
  // });
  const theme = useTheme();
  return (
    <div id={id}>
      <Box
        className={"glass-card"}
        sx={{
          width: "100%",
          height: "auto",
          display: "flex",
          justifyContent: "center",
          p: "auto",

          // backgroundImage:
          //   "linear-gradient(99deg, rgba(16,0,46,1) 2%, rgba(9,21,121,0) 95%)",
        }}
      >
        <Box
          m={"80px 0 0px 0px"}
          sx={{
            // backgroundColor: "#281d5a",
            width: {
              xs: 250,
              sm: 350,
              md: 475,
              lg: 650,
            },
          }}
        >
          <Box
            width="100%"
            m={"20px 0 20px 0px"}
            sx={{
              display: "flex",
              justifyContent: "left",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: "white",
                fontFamily: theme.typography.fontFamily,
              }}
            >
              Hi, I'm Binat
            </Typography>
          </Box>
          {/* <Card
            align={"center"}
            sx={{ background: "transparent" }}
            // elevation={3}
          > */}
          {/* <CardContent>
              <Typography
                variant="h2"
                gutterBottom
                sx={{ color: "white", fontWeight: "bold" }}
              >
                About Me
              </Typography>
            </CardContent> */}
          <Box
            // className="small-glass-card"
            sx={{
              // padding: "10px",
              height: "auto",
              width: {
                xs: 250,
                sm: 350,
                md: 475,
                lg: 650,
              },
            }}
          >
            <Typography
              // variant="body1"
              padding={"0px"}
              textAlign={"left"}
              sx={{
                fontSize: 16,
                color: "whitesmoke",
                fontFamily: theme.typography.fontFamily,
              }}
              m={"3px 0 0 0"}
            >
              UK based software developer and writer. Using Javascript, React
              and Python to building Websites, GUI's and more.
            </Typography>
          </Box>
          {/* </Card> */}
        </Box>
      </Box>
    </div>
  );
};

export default About;
