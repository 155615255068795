import React from "react";
import { Menu as MenuIcon } from "@mui/icons-material";
// import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  MenuItem,
  Menu,
  Box,
} from "@mui/material";

// import { animateScroll as scroll } from "react-scroll";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
// import { setMode } from "state/globalSlice";
// import Brightness4Icon from "@mui/icons-material/Brightness4";
// import Brightness7Icon from "@mui/icons-material/Brightness7";
// import { useDispatch } from "react-redux";

const Navbar = ({ isTopOfPage }) => {
  // const theme = useTheme();
  const location = useLocation();
  // const dispatch = useDispatch();
  const pages = [
    { pageTo: "/#home", pageName: "Home", homeTo: "/" },
    { pageTo: "/#projects", pageName: "Projects", homeTo: "/" },
    { pageTo: "/#blog", pageName: "Blog", homeTo: "/" },
    { pageTo: "/#contact", pageName: "Contact", homeTo: "/" },
  ];

  // const scrollToTop = () => {
  //   scroll.scrollToTop();
  // };

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      // let dims = elem.getBoundingClientRect();
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  // if the current path is /habit then use the homeTo
  return (
    <AppBar
      // className="glass-card"
      sx={{
        // position: "fixed",
        display: "flex",
        background: "#121212",
        overflow: true,
      }}
      elevation={1}
    >
      <Toolbar
        sx={{
          color: "white",
          justifyContent: "center",
        }}
      >
        {/* LEFT SIDE */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            width: {
              xs: 250,
              sm: 350,
              md: 475,
              lg: 650,
            },
          }}
        >
          {/* <Typography>NI</Typography> */}
          {/* <IconButton
            sx={{ ml: 1 }}
            onClick={() => dispatch(setMode())}
            color="inherit"
          >
            {theme.palette.mode === "dark" ? (
              <Brightness7Icon />
            ) : (
              <Brightness4Icon />
            )}
          </IconButton> */}
          {/* <IconButton onClick={scrollToTop}>
            <ArrowUpwardIcon style={{ color: "white" }} />
          </IconButton> */}
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
                "& .MuiPaper-root": {
                  className: "glass-card",
                  background: "transparent",
                  padding: "10px 0 0 0",
                },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.pageTo} onClick={handleCloseNavMenu}>
                  <Link
                    activeClass="active"
                    to={page.pageTo}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    {page.pageName}
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            className="nav-item"
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <Typography sx={{ m: "0px" }} variant="h5">
              <Link
                // activeClass="active"
                to="/#home"
                // spy={true}
                // smooth={true}
                // offset={-70}
                // duration={500}
                // color="white"
                style={{ textDecoration: "none", color: "white" }}
              >
                Home
              </Link>
            </Typography>
          </Box>
          <Box
            className="nav-item"
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <Typography sx={{ m: "10px" }} variant="h5">
              <Link
                // activeClass="active"
                to="/#majorprojects"
                // spy={true}
                // smooth={true}
                // offset={-70}
                // duration={500}
                style={{ textDecoration: "none", color: "white" }}
              >
                Projects
              </Link>
            </Typography>
          </Box>
          {/* <Box
            className="nav-item"
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <Typography sx={{ m: "10px" }} variant="h5">
              <Link
                activeClass="active"
                to="/blog"
                // spy={true}
                // smooth={true}
                // offset={-70}
                // duration={500}
                style={{ textDecoration: "none", color: "white" }}
              >
                Blog
              </Link>
            </Typography>
          </Box> */}
          <Box
            className="nav-item"
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <Typography sx={{ m: "0px" }} variant="h5">
              <Link
                activeClass="active"
                to="/#contact"
                // spy={true}
                // smooth={true}
                // offset={-100}
                // duration={500}
                style={{ textDecoration: "none", color: "white" }}
              >
                Contact
              </Link>
            </Typography>
          </Box>
        </Box>
        {/* RIGHT SIDE */}
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            width: {
              xs: 250,
              sm: 350,
              md: 475,
              lg: 750,
            },
          }}
        ></Box> */}
        {/* <IconButton onClick={() => dispatch(setMode())}>
          {theme.palette.mode === "dark" ? (
            <LightModeOutlined sx={{ fontSize: "20px" }} />
          ) : (
            <DarkModeOutlined sx={{ fontSize: "20px" }} />
          )}
        </IconButton> */}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
