import { CssBaseline, ThemeProvider, Box } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { themeSettings } from "theme";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "scenes/Homepage";
import Layout from "scenes/Layout";
import HabitTracker from "scenes/HabitTracker";
import AllProjects from "scenes/AllProjects";
import BlogArticle from "scenes/BlogArticle";
import BlogLists from "scenes/BlogLists";
// import Blog from "scenes/Blog";
import Blogs from "scenes/Blogs";
import Contact from "scenes/Contact";

function App() {
  const mode = useSelector((state) => state.global.mode);

  // const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  // const darkTheme = createTheme({
  //   palette: {
  //     mode: "dark",
  //   },
  // });

  document.title = "Binat - Developer, Writer";
  // const [isTopOfPage, setIsTopOfPage] = useState(true);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY === 0) {
  //       setIsTopOfPage(true);
  //     }

  //     if (window.scrollY !== 0) setIsTopOfPage(false);
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  return (
    <div className="app">
      <ThemeProvider theme={theme}>
        <CssBaseline>
          {/* <Route path="/posts" element={<Posts />}>
          <Route index element={<PostLists />} />
          <Route path=":slug" element={<Post />} />
        </Route> */}
          <Box className={""}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Layout />}>
                  {/* public routes */}
                  <Route index element={<Homepage />} />
                  <Route path="habit" element={<HabitTracker />}></Route>
                  <Route path="projects" element={<AllProjects />}></Route>
                  <Route path="blog" element={<Blogs />}>
                    <Route index element={<BlogLists />} />
                    <Route path=":slug" element={<BlogArticle />} />
                  </Route>
                  <Route path="contact" element={<Contact />}></Route>
                </Route>
              </Routes>
            </BrowserRouter>
          </Box>
        </CssBaseline>
      </ThemeProvider>
    </div>
  );
}

export default App;
