import dinner from "../assets/Random_Dinner.png";
import promodo from "../assets/Blue_Promodo.png";
import stopwatch from "../assets/Stopwatch.png";
import fileSorter from "../assets/File-Sorter.png";
import nasa from "../assets/NASA-APOD.png";
import URLShortener from "../assets/URL-SHORTNER.png";
import habit from "../assets/HabitBuilder.png";
const Data = [
  {
    id: "1",
    title: "Random Dinner",
    category: "React",
    cat: ["React", "Django", "MUI"],
    img: dinner,
    desc: "A dinner selector",
    github: "https://github.com/naturalneuralnet/home-dinner",
    live: "https://dinner-shuffle.onrender.com/",
  },
  {
    id: "2",
    title: "Stopwatch",
    category: "React",
    cat: ["React", "Javascript", "Material UI"],
    img: stopwatch,
    desc: " A stopwtach with a CRT theme",
    github: "https://github.com/naturalneuralnet/crt-timer",
    live: "https://crt-timer.onrender.com/",
  },
  {
    id: "3",
    title: "Pomodoro",
    category: "React",
    cat: ["React", "Javascript", "Material UI"],
    img: promodo,
    desc: " Pomodoro timer with blue ocean theme",
    github: "https://github.com/naturalneuralnet/blue-pomodoro",
    live: "https://pomodoro-blue.onrender.com/",
  },
  {
    id: "4",
    title: "NASA-APOD",
    category: "Django",
    cat: ["Django", "Python", "CSS", "HTML"],
    img: nasa,
    desc: " Astronaut Picture of the Day from NASA",
    github: "https://github.com/naturalneuralnet/nasa-apod",
    live: "https://nasa-apod-xo69.onrender.com/",
  },
  {
    id: "5",
    title: "UK Indeed Scraper",
    category: "Scrapy",
    cat: ["Python", "Scrapy"],
    img: fileSorter,
    desc: "Web Scraper for UK Indeed website",
    github: "https://github.com/naturalneuralnet/uk-indeed-scraper",
    live: "https://github.com/naturalneuralnet/uk-indeed-scraper",
  },
  {
    id: "6",
    title: "Habit Maker",
    category: "React",
    cat: ["React", "Node.js", "MongoDB", "Express.js"],
    img: habit,
    desc: "Habit Tracker",
    github: "https://github.com/naturalneuralnet/habitBuilder-client",
    live: "https://habitmaker.onrender.com/",
  },
  {
    id: "7",
    title: "URL Shortener",
    category: "Django",
    cat: ["Django", "Python", "HTML", "CSS"],
    img: URLShortener,
    desc: "URL Shortener",
    github: "https://github.com/naturalneuralnet/url-shortner",
    live: "https://url-shortener-4ju1.onrender.com",
  },
  {
    id: "8",
    title: "File-Sorter",
    category: "Tkinter",
    cat: ["Python", "Tkinter"],
    img: fileSorter,
    desc: " Python file sorter with Tkinter GUI",
    github: "https://github.com/naturalneuralnet/file-sorter",
    live: "https://github.com/naturalneuralnet/file-sorter",
  },
  // {
  //   id: "9",
  //   title: "Auto Project Starter",
  //   category: "Tkinter",
  //   cat: ["Python"],
  //   img: fileSorter,
  //   desc: " Python project file generator",
  //   github: "https://github.com/naturalneuralnet/auto-python-project",
  // },
];

export default Data;
