import React from "react";
import About from "./About";
// import MajorProjects from "./MajorProjects";
import AllProjects from "./AllProjects";
// import Blog from "./Blog";
// import BlogLists from "./BlogLists";
// import { Divider } from "@mui/material";
// import Footer from "./Footer";
import Contact from "./Contact";
const Homepage = () => {
  return (
    <>
      <About id="home"></About>

      {/* <MajorProjects id="majorprojects"></MajorProjects> */}
      <AllProjects id={"majorprojects"}></AllProjects>

      {/* <Blog id="blog"></Blog> */}
      <Contact id="contact"></Contact>
      {/* <Footer></Footer> */}
      {/* <Skills id="skills"></Skills> */}
      {/* <AllProjects id="allprojects"></AllProjects> */}
    </>
  );
};

export default Homepage;
