import React from "react";
import {
  Stack,
  Box,
  Typography,
  CardContent,
  Button,
  styled,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import habitTrackerFront from "../assets/habitTrackerFront.png";
import habitTrackerLogin from "../assets/habitTrackerLogin.png";
// import monthly from "../assets/MonthlyCalc.png";
//import transparentMonthly from "../assets/MonthlyCalc.png";
import yearStructure from "../assets/YearlyStructure.drawio.png";
import emailVerification from "../assets/EmailVerification.png";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import js from "react-syntax-highlighter/dist/esm/languages/hljs/javascript";
import dark from "react-syntax-highlighter/dist/esm/styles/hljs/dark";
import ControlledAccordion from "components/ControlledAccordian";
// import { stackoverflowLight } from "react-syntax-highlighter/dist/esm/styles/hljs";
SyntaxHighlighter.registerLanguage("javascript", js);

const ColorButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  background: "rgba(255, 255, 255, 0.283)",
  backdropFilter: "blur(10px)",
  border: "1px solid rgba(255,255,255,0.25)",
  borderRadius: "1px",
  color: "white",
}));

const HabitTracker = () => {
  const theme = useTheme();
  const codeString = `const HabitSchema = new mongoose.Schema({
      user: {
        type: mongoose.Schema.Types.ObjectId,
        required: true,
        ref: "User",
      },
      name: {
        type: String,
      },
      year: {
        type: Array,
      },
    },
    { timestamps: true })`;
  return (
    <>
      <Box
        className={"glass-card2"}
        sx={{
          height: "auto",
          width: "auto",
          display: "flex",
          justifyContent: "center",
          padding: "0 0 20px 0",
          m: "0px 0 0 0px",
          // backgroundImage:
          //   "linear-gradient(190deg, rgba(16,0,46,1) 2%, rgba(9,21,121,0) 95%)",
        }}
      >
        <Stack spacing={2}>
          <Box
            width="100%"
            m={"80px 0 20px 0px"}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* <Typography
              variant="h3"
              sx={{ color: "white", fontFamily: theme.typography.fontFamily }}
            >
              Habit Tracker
            </Typography> */}
          </Box>

          <Stack spacing={3}>
            <Box
              className="small-glass-card"
              elevation={0}
              sx={{
                width: {
                  xs: 350,
                  sm: 750,
                  md: 950,
                  lg: 650,
                },
              }}
            >
              <CardContent
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "100%",
                  },
                }}
              >
                <Box padding={"5px"}>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent={"flex-end"}
                  >
                    <ColorButton size="small">Live Preview</ColorButton>
                    <ColorButton size="small">Source Code</ColorButton>
                  </Stack>
                </Box>
                <Typography
                  variant="h4"
                  m={"0 0px 5px 0"}
                  gutterBottom
                  sx={{
                    color: "white",
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: "bold",
                  }}
                  padding={"5px"}
                >
                  About
                </Typography>

                <Typography
                  m={"0 0 5px 0"}
                  gutterBottom
                  sx={{
                    fontSize: "17px",
                    color: "white",
                    fontFamily: theme.typography.fontFamily,
                  }}
                  padding={"5px"}
                >
                  This habit tracker is based on setting goals rather than
                  streaks. Progress is not lost, even if you miss a day, or a
                  week. Life happens and you can carry on building your habit.
                  No need to start over. Progress is tracked per month. This
                  flexibility and forgiveness makes it easier and more enjoyable
                  to build new habits.
                </Typography>
                <Stack direction={"row"} spacing={3} marginBottom={"8px"}>
                  {/* <Box display={"flex"} justifyContent={"center"}> */}
                  <img
                    src={habitTrackerFront}
                    alt="habitTracker Front"
                    width={"50%"}
                    height="100%"
                    style={{
                      border: "1px solid rgba(242, 189, 111, 0.598)",
                      boxShadow: "0 5px 32px 0 rgba(242, 189, 111 0.37)",
                    }}
                  ></img>
                  {/* </Box> */}
                  {/* <Box */}
                  {/* display={"flex"}
                  justifyContent={"center"}
                  marginBottom={"5px"}> */}
                  <img
                    src={habitTrackerLogin}
                    alt="habitTracker Front"
                    width={"50%"}
                    height="100%"
                    style={{
                      border: "1px solid rgba(242, 189, 111, 0.598)",
                      boxShadow: "0 5px 32px 0 rgba(242, 189, 111 0.37)",
                    }}
                  ></img>
                  {/* </Box> */}
                </Stack>
                <ControlledAccordion
                  sx={{
                    background: "black",
                  }}
                  title={"Features"}
                  subtitle={"Implemented Features"}
                  content={
                    <>
                      {" "}
                      <ul>
                        <li>User's can sign up for an account</li>
                        <li>
                          User's account is verified via email after sign up
                        </li>
                        <li>
                          User's can login to authenticate access to private
                          pages
                        </li>
                        <li>Users can create, update and delete habits</li>
                        <li>
                          A completion percentage is calculated and displayed
                          for each habit
                        </li>
                      </ul>
                    </>
                  }
                ></ControlledAccordion>
                {/* <ControlledAccordion
                  title={"What makes this Habit Tracker unique?"}
                  subtitle={"What makes this one different?"}
                  content={
                    <>
                      {" "}
                      <Typography
                        m={"0 0 5px 0"}
                        gutterBottom
                        sx={{
                          fontSize: "17px",
                          color: "white",
                          fontFamily: theme.typography.fontFamily,
                        }}
                        padding={"5px"}
                      >
                        <p>
                          This habit tracker is set apart from others because it
                          calculates progress as a percentage of the number of
                          completions in a month. This makes it more forgiving
                          and flexible. Progress is maintained even if days or
                          weeks are missed. It does not track progress based on
                          streaks.
                        </p>
                        Here is how the percentage progress is calculated:
                      </Typography>
                      <Box display={"flex"} justifyContent={"center"}>
                        <img
                          src={transparentMonthly}
                          alt="habitTracker Front"
                          width={"auto"}
                          height="auto"
                        ></img>
                      </Box>
                      <Typography
                        m={"0 0 0px 0"}
                        gutterBottom
                        sx={{
                          color: "white",
                          fontSize: "17px",
                          fontFamily: theme.typography.fontFamily,
                        }}
                        padding={"5px"}
                      >
                        <p>
                          Despite only completing the habit for 5 different days
                          across the month, or 16% of the month the progress is
                          maintained.
                        </p>
                        <p>This technique has two major benfits:</p>
                        <ul>
                          <li>
                            Any completed days count towards the monthly
                            percentage. There is no need to discard progress and
                            start over anew each week. Progress is not lost if a
                            day is missed.
                          </li>
                          <li>
                            This means that the user has some progress to build
                            the habit up from. This is easier than starting from
                            zero
                          </li>
                        </ul>
                      </Typography>
                    </>
                  }
                ></ControlledAccordion> */}
                {/* <Typography
                  variant="h4"
                  m={"0 0 0px 0"}
                  gutterBottom
                  sx={{
                    color: "white",
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: "bold",
                  }}
                  padding={"5px"}
                >
                  Challanges
                </Typography>
                <Typography
                  m={"0 0 0px 0"}
                  gutterBottom
                  sx={{
                    fontSize: "17px",
                    color: "white",
                    fontFamily: theme.typography.fontFamily,
                  }}
                  padding={"5px"}
                >
                  I encountered many challanges when building this out.
                  <ul>
                    <li>MongoDb CRUD </li>
                    <li>React-Redux </li>
                    <li>Authentication</li>
                    <li>Email Verification</li>
                  </ul>
                </Typography> */}
                <ControlledAccordion
                  title={"Database Structure"}
                  subtitle={"Detailed Explanation of the Habit Data Model"}
                  content={
                    <>
                      {" "}
                      <Typography
                        m={"0 0 0px 0"}
                        gutterBottom
                        sx={{
                          fontSize: "17px",
                          color: "white",
                          fontFamily: theme.typography.fontFamily,
                        }}
                        padding={"5px"}
                      >
                        <p>
                          Each Habit model has a one to many relationship with
                          the User model. A User can have many Habits, but a
                          Habit can only belong to one user. The User ID is
                          specified in each habit model. This allows the Express
                          API to programmatically query the database for a list
                          of each user's habits with their unique User ID.
                        </p>
                        <p>This is the Habit Model Schema:</p>

                        <SyntaxHighlighter
                          language="javascript"
                          style={dark}
                          showLineNumbers
                          wrapLongLines
                        >
                          {codeString}
                        </SyntaxHighlighter>
                      </Typography>
                      <Typography
                        variant="h5"
                        m={"0 0 0px 0"}
                        gutterBottom
                        sx={{
                          color: "white",
                          fontFamily: theme.typography.fontFamily,
                          fontWeight: "bold",
                        }}
                        padding={"5px"}
                      >
                        Habit Internal Data Structure
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "17px",
                          color: "white",
                          fontFamily: theme.typography.fontFamily,
                        }}
                        padding={"5px"}
                      >
                        The year object in the Habit model has a specific
                        structure that enables me to easily calculate the
                        percentage completion for each month, store and update
                        that percentage as well as provide a historical view of
                        the completions for that habit. I've created this
                        infographic to visualize and explain that structure:
                      </Typography>
                      <Box display={"flex"} justifyContent={"center"}>
                        <img
                          src={yearStructure}
                          alt="habitTracker Front"
                          width={"100%"}
                          height="auto"
                        ></img>
                      </Box>
                    </>
                  }
                ></ControlledAccordion>
                <ControlledAccordion
                  title={"Authentication"}
                  subtitle={"The process used to authenticate users"}
                  content={
                    <>
                      <p>
                        JSON Web Tokens were used for authentication. This
                        tutorial was extremely helpful in learning how to
                        implement JSON Web Tokens for User authentication and
                        page access.
                      </p>
                      <p>
                        After a user successfully logs in, two tokens, the
                        authentication token and the refresh token are created.
                        The authentication token is checked for validity before
                        the user is allowed to access a page. The refresh token
                        is used to request a new authentication token if the
                        original is expired.
                      </p>

                      <p>
                        Some pages are restricted based on the User's role.
                        Admin accounts can view and delete users. The tokens are
                        stored in a cookie instead of Local Storage for security
                        purposes.
                      </p>
                    </>
                  }
                ></ControlledAccordion>
                <ControlledAccordion
                  title={"Email Verification"}
                  content={
                    <>
                      <p>
                        Email verification adds an extra layer of security for
                        both the user and the product owner. For the product
                        owner, it assures that the user who signed up is the
                        same entity as the email owner, a genuine user and not a
                        bot. For the user, it adds an established communication
                        route in case of problems with the service, such as a
                        forgotten password or other errors.
                      </p>
                      <p>
                        The nodemailer package was used to automate the creation
                        and sending of an email when the User signs up. The code
                        from this
                        <a
                          style={{ textDecoration: "none", color: "goldenrod" }}
                          href="https://betterprogramming.pub/how-to-create-a-signup-confirmation-email-with-node-js-c2fea602872a"
                        >
                          {" "}
                          tutorial{" "}
                        </a>
                        was adapted to work with the Redux Toolkit.
                      </p>
                      <p>
                        The confirmation code is created with a JSON Web token
                        signed with a secret SSH key. This ensures each code is
                        unique. The flow of events is as follows:
                      </p>
                      <ul>
                        <li> 1. User signs up</li>
                        <li>
                          {" "}
                          2. An email verification link is sent to the provided
                          email address with nodemailer
                        </li>
                        <li>
                          {" "}
                          3. Upon clicking the verification link the user is
                          taken verification page
                        </li>
                        <li>
                          {" "}
                          4. This page checks the URL and then makes a request
                          to the useVerify endpoint with the confirmation code
                          via the useVerifyMuatation
                        </li>
                        <li>
                          {" "}
                          5. The endpoint is associated with the verify
                          controller in the backend and extracts the
                          confirmation code from the request body
                        </li>
                        <li>
                          {" "}
                          6. The controller then searches the database for the
                          User with the matching confirmation code and updates
                          the User's status from pending to active
                        </li>
                        <li> 7. The User can now login</li>
                      </ul>
                      <p>
                        Diagram created with
                        <a
                          style={{ textDecoration: "none", color: "goldenrod" }}
                          href="https://app.diagrams.net/"
                        >
                          {" "}
                          appdiagrams{" "}
                        </a>
                        to visualize this process:
                      </p>
                      <Box display={"flex"} justifyContent={"center"}>
                        {" "}
                        <img src={emailVerification} alt="email"></img>
                      </Box>
                    </>
                  }
                ></ControlledAccordion>
                <ControlledAccordion
                  title={"Technologies"}
                  subtitle={"List of Technologies used in the project"}
                  content={
                    <ul>
                      <li>
                        <b>React</b> - Frontend Framework
                      </li>
                      <li>
                        <b>React-Redux & Redux Toolkit</b> - State management
                      </li>
                      <li>
                        <b>React Router</b> - Routing
                      </li>
                      <li>
                        <b>Material UI</b> - User Interface Library
                      </li>
                      <li>
                        <b>CSS </b>- Styling
                      </li>
                      <li>
                        <b>Express</b> - Server
                      </li>
                      <li>
                        <b>Node.js</b> - Backend Framework
                      </li>
                      <li>
                        <b>NPM</b> - Package Manager
                      </li>
                      <li>
                        <b>Mongo DB </b>- Database
                      </li>
                      <li>
                        <b>JWT</b> - Authentication
                      </li>
                      <li>
                        <b>Git & Github</b> - Version Control
                      </li>
                      <li>
                        <b>Render.com </b>- Deployment
                      </li>
                    </ul>
                  }
                ></ControlledAccordion>
                <Box
                  padding={"5px"}
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                >
                  <ColorButton size="small">
                    <Link
                      to={"/#majorprojects"}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      Back to Projects
                    </Link>
                  </ColorButton>
                </Box>
              </CardContent>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default HabitTracker;
