import { amber, deepOrange, grey } from "@mui/material/colors";

export const tokensDarkOld = {
  main: {
    0: "#ffffff", // manually adjusted
    10: "#f6f6f6", // manually adjusted
    50: "#f0f0f0", // manually adjusted
    100: "#e0e0e0",
    200: "#c2c2c2",
    300: "#a3a3a3",
    400: "#858585",
    500: "#666666",
    600: "#525252",
    700: "#3d3d3d",
    800: "#292929",
    900: "#141414",
    1000: "#000000", // manually adjusted
  },
  primary: {
    // blue
    100: "#d3d4de",
    200: "#a6a9be",
    300: "#7a7f9d",
    400: "#4d547d",
    500: "#21295c",
    600: "#191F45", // manually adjusted
    700: "#141937",
    800: "#0d1025",
    900: "#070812",
  },
  secondary: {
    // yellow
    50: "#f0f0f0", // manually adjusted
    100: "#fff6e0",
    200: "#ffedc2",
    300: "#ffe3a3",
    400: "#ffda85",
    500: "#ffd166",
    600: "#cca752",
    700: "#997d3d",
    800: "#665429",
    900: "#332a14",
  },
};

/// reverses the token colors

const tokensDark = {
  //// deep blue
  primary: {
    50: "#e4eeff",
    100: "#cfdfff",
    200: "#a8c2ff",
    300: "#7499ff",
    400: "#3e5cff",
    500: "#1322ff",
    600: "#0009ff",
    700: "#0009ff",
    800: "#0008e4",
    900: "#0000b0",
    950: "#010026",
  },
  /// light blue
  main: {
    0: "#ffffff", // manually adjusted
    10: "#f6f6f6", // manually adjusted
    50: "#f0f0f0", // manually adjusted
    100: "#e0e0e0",
    200: "#c2c2c2",
    300: "#a3a3a3",
    400: "#858585",
    500: "#666666",
    600: "#525252",
    700: "#3d3d3d",
    800: "#292929",
    900: "#141414",
    1000: "#000000", // manually adjusted
  },
  secondary: {
    50: "#fcf3f8",
    100: "#fae9f3",
    200: "#f8d2e9",
    300: "#f3aed4",
    400: "#ea7cb8",
    500: "#dc4492",
    600: "#cd357c",
    700: "#b12562",
    800: "#932151",
    900: "#7b2047",
    950: "#4a0d26",
  },
};

function reverseTokens(tokensDark) {
  const reversedTokens = {};

  Object.entries(tokensDark).forEach(([key, val]) => {
    const keys = Object.keys(val);

    const values = Object.values(val);

    const length = keys.length;

    const reversedObj = {};

    for (let i = 0; i < length; i++) {
      reversedObj[keys[i]] = values[length - i - 1];
    }

    reversedTokens[key] = reversedObj;
  });

  return reversedTokens;
}

export const tokensLight = reverseTokens(tokensDark);

// this is to make changing the font easier

const font = ["Montserrat", "sans-serif"];
// const font2 = ["Libre Baskerville", "sans-serif"];

// mui theme settings

export const themeSettings = (mode) => {
  return {
    palette: {
      mode: mode,

      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: deepOrange,
            divider: deepOrange[700],
          }
        : {
            // palette values for light mode

            // palette values for light mode
            background: {
              default: grey[300],
            },
            primary: grey,
            divider: amber[200],
            text: {
              primary: grey[900],
              secondary: grey[800],
            },
          }),
    },

    typography: {
      mode: mode,

      fontFamily: font.join(","),

      fontSize: 12,

      h1: {
        fontFamily: font.join(","),

        fontSize: 40,
      },

      h2: {
        fontFamily: font.join(","),

        fontSize: 32,
      },

      h3: {
        fontFamily: font.join(","),

        fontSize: 24,
      },

      h4: {
        fontFamily: font.join(","),

        fontSize: 20,
      },

      h5: {
        fontFamily: font.join(","),

        fontSize: 16,
      },

      h6: {
        fontFamily: font.join(","),

        fontSize: 14,
      },
      ...(mode === "dark"
        ? {
            body1: {
              fontFamily: font.join(","),

              fontSize: 14,
              color: grey[500],
            },
          }
        : {
            body1: {
              fontFamily: font.join(","),

              fontSize: 14,
              color: grey[800],
            },
          }),
    },
  };
};
