//
// date posted and author
// image
// text
import { Typography, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import BlogPosts from "./BlogData";
import React from "react";

const BlogArticle = () => {
  const { slug } = useParams();
  const post = BlogPosts[slug];
  if (!post) {
    return <span>The blog post you've requested doesn't exist.</span>;
  }
  const { title, content } = post;
  return (
    <Box
      className={"glass-card"}
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        p: "auto",
      }}
      direction="column"
    >
      <Box
        m={"80px 0 20px 0px"}
        sx={{
          width: {
            xs: 250,
            sm: 350,
            md: 475,
            lg: 650,
          },
        }}
      >
        <Box>
          <Typography
            variant="h3"
            sx={{
              color: "white",
              // fontFamily: theme.typography.fontFamily,
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="h5"
            sx={{
              color: "white",
              // fontFamily: theme.typography.fontFamily,
            }}
          >
            Date Posted & Author
          </Typography>
        </Box>
        <Box
          variant="h2"
          sx={{
            color: "white",
            // fontFamily: theme.typography.fontFamily,
          }}
        >
          {/* <Typography>{description}</Typography> */}
          {content}
        </Box>
      </Box>
    </Box>
  );
};

export default BlogArticle;
