//import { useTheme } from "@emotion/react";
import { Typography, Box, Stack, Grid, useTheme } from "@mui/material";

import ProjectCard from "components/ProjectCard";
// import React, { useState } from "react";
import Data from "./ProjectsData";
//import ProjectButtons from "components/ProjectButtons";
import { CardSpotlightEffect } from "components/CardSpotLightEffect";

const AllProjects = ({ id }) => {
  const newData = Data;
  const item = newData;
  // const [item, setItem] = useState(newData);
  //const [project, setProject] = useState(ProjectsData);
  const theme = useTheme();

  // hardcoded categories and symbols

  const symbols = [
    {
      type: "Python",
      src: "https://www.svgrepo.com/show/374016/python.svg",
    },
    {
      type: "React",
      src: "https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg",
    },
    {
      type: "MongoDB",
      src: "https://www.svgrepo.com/show/331488/mongodb.svg",
    },
    {
      type: "Django",
      src: "https://www.svgrepo.com/show/373554/django.svg",
    },
    {
      type: "Scrapy",
      src: "https://scrapy.org/img/scrapylogo.png",
    },
    {
      type: "Bash",
      src: "https://www.svgrepo.com/show/353478/bash-icon.svg",
    },
  ];
  /// gets all the unique categories
  // const menuItems = [...new Set(Data.map((Val) => Val.category))];

  // gets categories from array in cat part of data
  const buttonItems = [];
  Data.map((Val) => Val.cat.map((val) => buttonItems.push(val)));

  /// filters the data for items that match the current category
  // const callFilterItem = () => {
  //   for (let j = 0; Data.length; j++) {
  //     for (let i = 0; i < Data[j].cat.length; i++) {
  //       let potCat = Data[j].cat[i];
  //     }
  //   }
  // };
  /// change this to tak in an array and loop through and filter based on all items
  /// in the array - right now it only takes in one category
  // then do a for loop and filter accordingly
  // const filterItem = (curcat) => {
  //   // console.log(curcat);
  //   // callFilterItem();
  //   const filteredItem = Data.filter((newVal) => {
  //     console.log(newVal);
  //     return newVal.cat.includes(curcat);
  //     // comparing category for displaying data
  //   });

  //   setItem(filteredItem);
  // };

  // const filterArray = (Data, newVal.cat, curcat) => {
  //   const filtered = Data.filter((el) => {
  //     return newVal.cat.indexOf(el) === curcat;
  //   });
  //   return filtered;
  // };

  return (
    <div id={id}>
      <Box
        // className={"glass-card"}
        sx={{
          height: "auto",
          width: "auto",
          display: "flex",
          justifyContent: "center",
          p: "0 0 20px 0px",
          m: "0px 0 0 0px",
        }}
      >
        <Stack
          spacing={2}
          display={"flex"}
          justifyContent={"center"}
          sx={{
            width: {
              xs: 250,
              sm: 350,
              md: 475,
              lg: 650,
            },
          }}
        >
          <Box
            m={"20px 0 0px 0px"}
            padding={"0px"}
            display={"flex"}
            justifyContent={"left"}
          >
            <Typography
              variant="h3"
              m={"20px 0 0px 0"}
              color={"white"}
              fontFamily={theme.typography.fontFamily}
            >
              Projects
            </Typography>
          </Box>
          {/* <Box
            sx={{
              width: {
                xs: 250,
                sm: 350,
                md: 475,
                lg: 750,
              },
            }}
            display="flex"
            justifyContent={"center"}
          >
            <ProjectButtons
              setItem={setItem}
              menuItems={symbols}
              filterItem={filterItem}
            ></ProjectButtons>
          </Box> */}

          <Grid
            container
            //spacing={{ xs: 3, sm: 4, md: 8, lg: 1 }}
            //columns={{ xs: 3, sm: 4, md: 8, lg: 1 }}

            display={"flex"}
            alignItems={"left"}
            justifyContent={"left"}
            columnSpacing={3}
            rowSpacing={3}
          >
            {item.map((Val, index) => {
              return (
                <Grid item key={index}>
                  <CardSpotlightEffect
                    content={
                      <ProjectCard Val={Val} menuItems={symbols}></ProjectCard>
                    }
                  ></CardSpotlightEffect>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      </Box>
    </div>
  );
};

export default AllProjects;
