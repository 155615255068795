import HabitTracker from "./HabitTracker";

const BlogPosts = {
  "MERN-Stack": {
    title: "MERN Stack Habit Tracker",
    description: "Lorem ipsum dolor sit amet, consectetur adip.",
    content: <HabitTracker></HabitTracker>,
  },
  "Indeed-UK-Web-Scraper": {
    title: "UK Indeed Web Scraper",
    description: "Hello React Router v6",
  },
};

export default BlogPosts;
