import {
  CardMedia,
  Typography,
  useTheme,
  Box,
  Stack,
  Grid,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

// const ColorButton = styled(Button)(({ theme }) => ({
//   background: "rgba(255, 255, 255, 0.1)",
//   // backdropFilter: "blur(4px)",
//   border: "1px solid rgba(255,255,255,0.25)",
//   borderRadius: "1px",
// }));

// const symbols = [
//   {
//     type: "Python",
//     src: "https://www.svgrepo.com/show/374016/python.svg",
//   },
//   {
//     type: "React",
//     src: "https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg",
//   },
//   {
//     type: "MongoDB",
//     src: "https://www.svgrepo.com/show/331488/mongodb.svg",
//   },
//   {
//     type: "Django",
//     src: "https://www.svgrepo.com/show/373554/django.svg",
//   },
//   {
//     type: "Scrapy",
//     src: "https://scrapy.org/img/scrapylogo.png",
//   },
//   {
//     type: "Bash",
//     src: "https://www.svgrepo.com/show/353478/bash-icon.svg",
//   },
// ];

// let isSame = false;
const ProjectCard = ({ Val, menuItems }) => {
  const theme = useTheme();
  // const newButtons = menuItems.map((item) => {
  //   return Val.cat.includes(item.type);
  // });

  // const filteredButtons = [];

  // menuItems.map((item) => {
  //   Val.cat.map((names) =>
  //     item.type === names ? filteredButtons.push(item) : ""
  //   );
  // });

  // const newButtons = Val.cat.map((item) => {
  //   return menuItems.includes(item);
  // });

  // console.log(newButtons);
  // const checkTwoArrays = (a, b) => {
  //   if (a === b) return true;
  //   if (a == null || b == null) return false;
  //   if (a.length !== b.length) return false;

  //   // If you don't care about the order of the elements inside
  //   // the array, you should sort both arrays here.
  //   // Please note that calling sort on an array will modify that array.
  //   // you might want to clone your array first.

  //   for (var i = 0; i < a.length; ++i) {
  //     console.log(a[i]);
  //     console.log(b[i]);
  //     if (a[i] !== b[i]) return false;
  //   }
  //   return true;
  // };
  // let answer = [];
  // const testCheck = () => {
  //   let count = 0;
  //   for (var i = 0; i < Val.cat.length; ++i) {
  //     count++;
  //     for (var v = 0; v < menuItems.length; v++) {
  //       count++;
  //       if (Val.cat[i] === menuItems[v].type) {
  //         console.log(count);
  //         answer.push(
  //           <img
  //             height="20px"
  //             width={"auto"}
  //             src={menuItems[v].src}
  //             alt="symbol"
  //           ></img>
  //         );
  //       }
  //     }
  //   }
  //   return answer;
  // };

  // const testCheck = () => {
  //   for (var i = 0; i < Val.cat.length; ++i) {
  //     console.log(Val.cat[i]);
  //   }
  // };
  return (
    <>
      <Box
        // className="small-glass-card"
        key={Val.id}
        height={"230px"}
        sx={{
          borderRadius: "15px",
          width: { xs: "290px", sm: "290px", md: "300px", lg: "300px" },
        }}
        display="flex"
        justifyContent="center"
      >
        {/* <CardContent
         display="flex"
        justifyContent="center"
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "100%",
            },
          }}
        > */}
        <Link
          to={Val.live}
          style={{
            textDecoration: "none",
          }}
        >
          <Stack
            height={"100%"}
            width={"100%"}
            alignItems={"left"}
            display={"flex"}
          >
            <CardMedia
              component="img"
              height="30px"
              image={Val.img}
              alt={Val.title}
              sx={{
                marginBottom: "5px",
                opacity: "0.8",
              }}
            ></CardMedia>
            <Box width={"300px"} padding={"20px"}>
              <Box height={"100px"}>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: 600,
                  }}
                  color={"white"}
                  gutterBottom
                >
                  {Val.title}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 16,
                    color: "white",
                    fontFamily: theme.typography.fontFamily,
                    marginTop: "5px",
                  }}
                  gutterBottom
                >
                  {Val.desc}
                </Typography>
              </Box>
              <Stack direction={"row"} spacing={1}>
                <Grid container spacing={1}>
                  {Val.cat.map((type, id) => {
                    return (
                      <Grid item spacing={1}>
                        <Box
                          sx={{
                            border: "1px solid #3a3a40",
                            height: "auto",
                            width: "auto",
                          }}
                        >
                          {/* <Stack direction={"row"}> */}
                          {/* <img
                        height="15px"
                        width="15px"
                        src={Val.src}
                        alt="Button Logo"
                        margin="5px"
                      ></img> */}
                          <Typography
                            // color={"#a6a9af"}
                            p={"3px"}
                            sx={{
                              fontFamily: theme.typography.fontFamily,
                              fontStyle: "italic",
                              fontSize: "14px",
                            }}
                          >
                            {type}
                          </Typography>
                          {/* </Stack> */}
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Stack>
            </Box>
            {/* <Stack direction={"row"} spacing={2}>
            <ColorButton>
              <Typography
                sx={{ fontSize: 10, fontFamily: theme.typography.fontFamily }}
                color="white"
              >
                Source code
              </Typography>
            </ColorButton>
            <ColorButton>
              <Typography
                sx={{ fontSize: 10, fontFamily: theme.typography.fontFamily }}
                color="white"
              >
                Live Preview
              </Typography>
            </ColorButton>
          </Stack> */}

            <Box className="typeBox" width={"100%"} padding={"20px"}>
              {/* <Typography color={"white"}>{item}</Typography> */}
            </Box>
          </Stack>
        </Link>
        {/* </CardContent> */}
      </Box>
    </>
  );
};

export default ProjectCard;
