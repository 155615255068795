import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import GitHubIcon from "@mui/icons-material/GitHub";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useState } from "react";
import { Link } from "react-router-dom";
import devto from "assets/mdi--dev-to.svg";
//import {sendEmail} from "../../public/server.js/index.js"

const ColorButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  background: "rgba(255, 255, 255, 0.1)",
  backdropFilter: "blur(10px)",
  border: "1px solid rgba(255,255,255,0.25)",
  borderRadius: "1px",
  color: "white",
}));

const Contact = ({ id }) => {
  const theme = useTheme();
  const [contactDetails, setContactDetails] = useState({
    name: "",
    email: "",
    message: "",
    emailSent: false,
  });

  /// name and message -> sends me an email
  const { name, email, message } = contactDetails;
  const handleChange = (e) => {
    e.preventDefault();

    setContactDetails({ ...contactDetails, [e.target.name]: e.target.value });
  };
  const sendMessage = async (e) => {
    e.preventDefault();
    const { name, email, message } = contactDetails;

    try {
      const response = await fetch(
        "https://nat-api-qm9r.onrender.com/sendEmail",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ name: name, email: email, message: message }),
        }
      );
      if (response.status === 200) {
        console.log("Email sent!. \nResponse:", response);
        setContactDetails({ ...contactDetails, emailSent: true });
      } else {
        console.log("Email not sent. \nResponse:", response);
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  return (
    //style={{ height: "94vh" }}
    <div id={id}>
      <Box
        sx={{
          height: "auto",
          width: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: "0px 0 0px 0px",
          m: "0px 0 40px 0px",
        }}
      >
        <Stack
          spacing={1}
          sx={{
            height: "100%",
            width: {
              xs: 250,
              sm: 350,
              md: 475,
              lg: 650,
            },
            display: "flex",
            justifyContent: "center",
            p: "0px 0 0px 0px",
            m: "10px 0 0 0px",
          }}
        >
          <Typography
            sx={{
              color: "white",
              display: "flex",
              justifyContent: "left",
              fontFamily: theme.typography.fontFamily,
            }}
            variant="h3"
            gutterBottom
            m={"0px 0 20px 0"}
          >
            Contact
          </Typography>
          <Stack
            direction={{
              xs: "column",
              md: "column",
              lg: "row",
            }}
            spacing={3}
          >
            <Box
              padding={"10px"}
              sx={{
                width: {
                  xs: 250,
                  sm: 300,
                  md: 350,
                  lg: 365,
                },
                borderRadius: "15px",
              }}
              className={"small-glass-card"}
            >
              <Stack spacing={1}>
                <form onSubmit={sendMessage}>
                  <Typography
                    sx={{
                      fontSize: 16,
                      margin: "0px 0px 8px 4px",
                      fontFamily: theme.typography.fontFamily,
                    }}
                    color={"white"}
                  >
                    Name
                  </Typography>
                  <TextField
                    // color="warning"
                    placeholder={"Name"}
                    value={name}
                    name="name"
                    onChange={handleChange}
                    size="small"
                    sx={{
                      marginBottom: "4px",
                      fontFamily: theme.typography.fontFamily,
                      "& .MuiOutlinedInput-root:hover": {
                        "& > fieldset": {
                          borderColor: "white",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "white",
                        width: "300px",
                      },
                    }}
                  ></TextField>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      margin: "0px 0px 8px 4px",
                      fontFamily: theme.typography.fontFamily,
                    }}
                    color={"white"}
                  >
                    Email Address
                  </Typography>
                  <TextField
                    // color="warning"
                    placeholder={"Email Address"}
                    size="small"
                    value={email}
                    name="email"
                    onChange={handleChange}
                    sx={{
                      marginBottom: "4px",
                      fontFamily: theme.typography.fontFamily,
                      "& .MuiOutlinedInput-root:hover": {
                        "& > fieldset": {
                          borderColor: "white",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "white",
                        width: "300px",
                      },
                    }}
                  ></TextField>
                  <Typography
                    sx={{
                      fontSize: "17px",
                      margin: "0px 0px 8px 4px",
                      fontFamily: theme.typography.fontFamily,
                    }}
                    color={"white"}
                  >
                    Message
                  </Typography>
                  <TextField
                    // color="warning"
                    placeholder={"Message"}
                    value={message}
                    name="message"
                    onChange={handleChange}
                    multiline
                    rows={4}
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      borderRadius: "15px",
                      "& .MuiOutlinedInput-root:hover": {
                        "& > fieldset": {
                          borderColor: "white",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "white",
                        width: "300px",
                      },
                    }}
                  ></TextField>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    marginTop={"10px"}
                  >
                    <ColorButton type="submit">Send Message</ColorButton>
                  </Box>
                </form>
                <Typography
                  sx={{
                    fontSize: "17px",
                    margin: "0px 0px 8px 4px",
                    fontFamily: theme.typography.fontFamily,
                  }}
                  color={"white"}
                >
                  {contactDetails.emailSent
                    ? "Your message has been sent! Thanks for reaching out!"
                    : ""}
                </Typography>
              </Stack>
            </Box>
            <Box
              // className={"small-glass-card"}
              padding={"10px"}
              sx={{
                width: {
                  xs: 250,
                  sm: 300,
                  md: 350,
                  lg: 355,
                },
              }}
              display={"flex"}
              justifyContent={"center"}
            >
              <Stack display={"flex"} justifyContent={"center"} spacing={3}>
                <Stack display={"flex"} justifyContent={"center"} spacing={1}>
                  <IconButton href="https://github.com/naturalneuralnet">
                    <GitHubIcon style={{ color: "white" }}></GitHubIcon>
                  </IconButton>

                  <IconButton>
                    <img
                      src={devto}
                      height="30px"
                      width={"auto"}
                      alt="devto"
                    ></img>
                    <Link to="https://dev.to/binat"></Link>
                  </IconButton>
                </Stack>
                {/* <Stack
                  spacing={2}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography
                    sx={{
                      "&:hover": { color: "whitesmoke", cursor: "pointer" },
                    }}
                  >
                    GitHub
                  </Typography> */}
                {/* <Link
                    style={{
                      textDecoration: "none",
                    }}
                    to={"https://github.com/naturalneuralnet"}
                  >
                    <Typography
                      sx={{
                        "&:hover": { color: "whitesmoke", cursor: "pointer" },
                      }}
                    >
                      Devto ↗
                    </Typography>
                  </Link> */}
                {/* <Link style={{ textDecoration: "none" }} to={"/contact"}>
                    <Typography
                      sx={{
                        "&:hover": { color: "whitesmoke", cursor: "pointer" },
                      }}
                    >
                      Message me directly from the contact page
                    </Typography>
                  </Link> */}
                {/* </Stack> */}
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </div>
  );
};

export default Contact;
