import React from "react";
import BlogPosts from "./BlogData";
import { Link } from "react-router-dom";
import { Box, CardContent, Typography, Stack, useTheme } from "@mui/material";

const BlogLists = () => {
  const theme = useTheme();
  return (
    <div style={{ height: "94vh" }}>
      <Box
        className={"glass-card"}
        sx={{
          height: "auto",
          width: "auto",
          display: "flex",
          justifyContent: "center",
          p: "0 0 0px 0px",
          m: "0px 0 0 0px",
        }}
      >
        <Stack
          spacing={2}
          display={"flex"}
          justifyContent={"center"}
          sx={{
            width: {
              xs: 250,
              sm: 350,
              md: 475,
              lg: 650,
            },
          }}
        >
          <Box
            m={"80px 0 0px 0px"}
            padding={"0px"}
            display={"flex"}
            justifyContent={"left"}
          >
            <Typography
              variant="h3"
              m={"20px 0 0px 0"}
              color={"white"}
              fontFamily={theme.typography.fontFamily}
            >
              Blog
            </Typography>
          </Box>

          {Object.entries(BlogPosts).map(([slug, { title }]) => (
            <CardContent
              key={slug}
              elevation={3}
              sx={{ backgroundColor: "#1a191b" }}
            >
              <Link
                to={`/blog/${slug}`}
                style={{
                  textDecoration: "none",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    color: "white",
                    // fontFamily: theme.typography.fontFamily,
                  }}
                >
                  {title}
                </Typography>
              </Link>
            </CardContent>
          ))}
        </Stack>
      </Box>
    </div>
  );
};

export default BlogLists;
