import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@emotion/react";

export default function ControlledAccordion({ title, subtitle, content }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const theme = useTheme();

  return (
    <>
      <Accordion
        // className={"gradient-background"}
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{ color: "black", "& .MuiSvgIcon-root": { color: "black" } }}
      >
        <AccordionSummary
          // className={"gradient-background"}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            background: "black",
          }}
        >
          <Typography
            variant="h4"
            m={"0 0px 5px 0"}
            gutterBottom
            sx={{
              color: "white",
              fontFamily: theme.typography.fontFamily,
              fontWeight: "bold",
            }}
            padding={"5px"}
          >
            {title}
          </Typography>
          {/* <Typography
            variant="h5"
            m={"0 0 0px 0"}
            textAlign={"center"}
            sx={{
              color: "white",
              fontFamily: theme.typography.fontFamily,
            }}
            padding={"7px 0px 0px 0px"}
          >
            {subtitle}
          </Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            m={"0 0 5px 0"}
            gutterBottom
            sx={{
              fontSize: "17px",
              color: "white",
              fontFamily: theme.typography.fontFamily,
            }}
            padding={"5px"}
          >
            {content}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
